import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ClientRequestService, ClientService } from "src/app/features/services";
import { BTNS_INVERTIR } from "src/app/shared/constants/landing-investments.constants";
import { ClientSearchModel, ClientSearchService, LoadingService } from "src/app/shared/services";

@Component({
    selector: 'app-landing-investments',
    templateUrl: './landing-investments.component.html',
    styleUrls: ['./landing-investments.component.scss'],
    providers: [],
})
export class LandingInvestmentsComponent implements OnInit {

    btnsInvertir = BTNS_INVERTIR;
    rutCliente;
    mainPath;

    constructor(
        private clientService: ClientService,
        private activateRoute: ActivatedRoute,
        private router: Router
    ) {

    }

    ngOnInit(): void {
        this.activateRoute.params.subscribe(param => {
            this.rutCliente = param.rut ?? null;
            this.mainPath = '/main/client-search/summary/' + this.rutCliente;
        });
    }

    get clientData() {
        try {
            return JSON.parse(sessionStorage.getItem("clientData"));
        } catch (err) {
            return null;
        }
    }

    volverAResumen() {
        this.router.navigate([this.mainPath], { queryParams: { from: 'wfi' } });
    }

    goTo(id: string) {
        
        const clientInfo = JSON.parse(sessionStorage.getItem("dataClient"));
        const origen = this.router.url;
        const cliente = {
            rut: `${clientInfo.RutCliente}-${clientInfo.DigitoVerificador}`,
            nombre: clientInfo.NombreCliente,
            apellidoPaterno: clientInfo.NombreCliente.split(' ')[0],
            apellidoMaterno: clientInfo.NombreCliente.split(' ')[1],
        }

        switch (id) {
            case 'invertir-no-apv':
                this.clientService.irAWFI(cliente, 'noapv', origen);
                break;
            case 'invertir-apv':
                this.clientService.irAWFI(cliente, 'apv', origen);
                break;
            case 'ingreso-ordenes-bolsa':
                this.router.navigate([this.mainPath + '/corredora-orden-compra-venta']);
                break;
            case 'ingreso-ordenes-divisas':
                this.router.navigate([this.mainPath + '/divisas-orden-compra-venta']);
                break;
        }
    }

}