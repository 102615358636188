import { RouterModule, Routes } from '@angular/router';
import { ClientSummaryComponent } from './client-summary.component';
import { InvestmentReportComponent } from './components/investment-report/investment-report.component';
import { PasswordsComponent } from './components/passwords/passwords.component';
import { ChangeProfileComponent } from './components/change-profile/change-profile.component';
import { NgModule } from '@angular/core';
import { MainClientSummaryComponent } from './components/main-client-summary/main-client-summary.component';
import { ProfileSummaryComponent } from './components/profile-summary/profile-summary.component';
import { BalanceCertificateComponent } from './components/balance-certificate/balance-certificate.component';
import { LandingInvestmentsComponent } from './components/landing-investments/landing-investments.component';

const routes: Routes = [
    {
        path: '',
        component: MainClientSummaryComponent,
        children: [
            {
                path: ':rut',
                component: ClientSummaryComponent,
                data: { breadcrumb: 'Resumen', supervisorTitle: { breadcrumb: 'Cartera de clientes' } },
            },
            {
                path: ':rut',
                data: { breadcrumb: 'Resumen', supervisorTitle: { breadcrumb: 'Cartera de clientes' } },
                children: [
                    {
                        path: 'informe-plataforma-nacional',
                        component: InvestmentReportComponent,
                        data: {
                            pershing: false,
                            breadcrumb: 'Informe plataforma nacional',
                        },
                    },
                    {
                        path: 'informe-plataforma-internacional',
                        component: InvestmentReportComponent,
                        data: {
                            pershing: true,
                            breadcrumb: 'Informe plataforma internacional',
                        },
                    },
                    {
                        path: 'password',
                        component: PasswordsComponent,
                        data: {
                            breadcrumb: 'Actualizar claves',
                        },
                    },
                    {
                        path: 'change-profile',
                        component: ChangeProfileComponent,
                        data: {
                            breadcrumb: 'Modificar perfil',
                        },
                    },
                    {
                        path: 'profile-resume',
                        component: ProfileSummaryComponent,
                        data: {
                            breadcrumb: 'Perfil',
                        },
                    },
                    {
                        path: 'landing-investments',
                        component: LandingInvestmentsComponent,
                        data: {
                            breadcrumb: 'Invertir',
                        },
                    },
                    {
                        path: 'balance-certificate',
                        component: BalanceCertificateComponent,
                        data: {
                            breadcrumb: 'Certificado de saldos',
                        },
                    },
                    {
                        path: 'pershing-selector-cuenta',
                        loadChildren: () => import('../asesor/pershing/selector-cuenta/selector-cuenta.module').then((m) => m.SelectorCuentaModule),
                        data: {
                             breadcrumb: 'Órdenes',
                        },
                    },
                    {
                        path: 'corredora-orden-compra-venta',
                        loadChildren: () => import('../asesor/corredora/orden-compra-venta/orden-compra-venta.module').then((m) => m.OrdenCompraVentaModule),
                        data: {
                            breadcrumb: 'Órdenes',
                        },
                    },
                    {
                        path: 'carro',
                        loadChildren: () => import('../asesor/pershing/orden-carro/orden-carro.module').then((m) => m.OrdenCarroModulePershing),
                        data: {
                            breadcrumb: 'Órdenes',
                        },
                    },

                    {
                        path: 'carro-finaliza',
                        loadChildren: () => import('../asesor/pershing/orden-carro-finaliza/orden-carro-finaliza.module').then((m) => m.OrdenCarroFinalizaModule),
                        data: {
                            breadcrumb: 'Órdenes',
                        },
                    },
                    {
                        path: 'corredora-carro',
                        loadChildren: () => import('../asesor/corredora/orden-carro/orden-carro.module').then((m) => m.OrdenCarroModuleCorredora),
                        data: {
                            breadcrumb: 'Órdenes',
                        },
                    },
                    {
                        path: 'corredora-finaliza',
                        loadChildren: () => import('../asesor/corredora/orden-carro-finaliza/orden-carro-finaliza.module').then((m) => m.OrdenCarroFinalizaModule),
                        data: {
                            breadcrumb: 'Órdenes',
                        },
                    },
                    {
                        path: 'divisas-carro',
                        loadChildren: () => import('../asesor/divisas/orden-carro/orden-carro.module').then((m) => m.OrdenCarroModuleDivisas),
                        data: {
                            breadcrumb: 'Órdenes',
                        },
                    },
                    {
                        path: 'divisas-finaliza',
                        loadChildren: () => import('../asesor/divisas/orden-carro-finaliza/orden-carro-finaliza.module').then((m) => m.OrdenCarroFinalizaModule),
                        data: {
                            breadcrumb: 'Órdenes',
                        },
                    },
                    {
                        path: 'divisas-orden-compra-venta',
                        loadChildren: () => import('../asesor/divisas/orden-compra-venta/orden-compra-venta.module').then((m) => m.OrdenCompraVentaModule),
                        data: {
                            breadcrumb: 'Órdenes',
                        },
                    },
                ],
            },
            { path: '**', redirectTo: '' },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ClientSummaryRoutingModule {}
