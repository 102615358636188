import { environment } from "@environment"

const iconpaths = "./../../../../../../assets/landing-investments"
export const BTNS_INVERTIR =  [
    {
        id: 'invertir-no-apv',
        icon: iconpaths + '/invertir_ffmm.svg',
        title: 'Invertir en Fondos Mutuos Régimen Normal',
        description: 'Invierte en nuestra amplia oferta de FFMM naconales e internacionales.',
        btnName: 'Invertir',
        activo: environment.WFI?.ACTIVO
    },
    {
        id: 'invertir-apv',
        icon: iconpaths + '/invertir_apv.svg',
        title: 'Invertir en Fondos Mutuos con APV',
        description: 'Accede a beneficios tributarios y aportes del Estado para favorecer los ahorros.',
        btnName: 'Invertir',
        activo: environment.WFI?.ACTIVO
    },
    {
        id: 'ingreso-ordenes-bolsa',
        icon: iconpaths + '/account_balance.svg',
        title: 'Ingresa órdenes de la corredora de bolsa',
        description: 'Ingresar órdenes de compra y venta de acciones locales, FIR, SURA, ETF Y Fondos de Inversión.',
        btnName: 'Ingresar',
        activo: true
    },
    {
        id: 'ingreso-ordenes-divisas',
        icon: iconpaths + '/icon-fx.svg',
        title: 'Ingresa órdenes de compra y venta de dólares',
        description: 'Ingresar órdenes de compra y venta de divisas.',
        activo: true,
        btnName: 'Ingresar'
    }
]