import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {datadogRum} from '@datadog/browser-rum';
import {environment} from '@environment';
import {version} from '@package-json';
import {LoginResponseModel} from '../../../features/models';
import {ContentfulService} from '../contentful/contentful.service';
import {HttpClient} from '@angular/common/http';
import * as LogRocket from "logrocket";
import { DialogMessageComponent, OptionsMessage } from '../../components/dialog-message/dialog-message.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '../dialog/dialog.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    private userLoggedIn = new Subject<boolean>();

    constructor(private contentfulService: ContentfulService, public http: HttpClient, public dialogService: DialogService, private router: Router) {
        this.userLoggedIn.next(false);
    }

    setUserLoggedIn(userLoggedIn: boolean) {
        this.userLoggedIn.next(userLoggedIn);
    }

    getUserLoggedIn(): Observable<boolean> {
        return this.userLoggedIn.asObservable();
    }

    public dataDogInit() {
        datadogRum.init({
            ...environment.DATADOG,
            version,
        });
    }

    public closeDatadogSession() {
        datadogRum.removeUser();
    }

    public async setDataDogUser(perfilAsesor: any, userName) {
        let recordingURL = 'LogRocket Deshabilitado';

        const logrocketEnabled = sessionStorage.getItem('logrocketEnabled');
        const enabledByEnv = logrocketEnabled === 'false' ? false : true;

        if (enabledByEnv) {
            recordingURL = await this.getRecordingURL();
            this.setRocketData(perfilAsesor);
        }

        const userData = {
            id: perfilAsesor.executiveRut,
            name: perfilAsesor.executiveName,
            email: userName,
            rut: perfilAsesor.executiveRut,
            profile: perfilAsesor.executiveProfile.profileName,
            recordingURL,
        };
        datadogRum.setUser(userData);
    }

    public getRecordingURL(): Promise<any> {
        return new Promise((resolve, reject) => {
            LogRocket.getSessionURL((sessionURL) => {
                resolve(sessionURL);
            });
        });
    }

    public setRocketData(perfilAsesor) {
        LogRocket.identify(perfilAsesor.executiveRut, {
            name: `${perfilAsesor.executiveName}`,
            rut: perfilAsesor.executiveRut,
        });
    }

    public async logRocketInit(startedOnSwitch?: boolean) {
        if (startedOnSwitch) return;
        let enabledByEnv = true;

        let logRocketFilter;

        const ambiente = environment.LOGROCKET.ENV.toString().trim().toUpperCase();
        try {
            logRocketFilter = await this.contentfulService.getEntryFromFront(environment.KEYS.CONTENTFUL.FILTER_LOGROCKET_BY_ENV);

            const environmentList = logRocketFilter.environmentList.environments;
            if (logRocketFilter.enabled && environmentList.includes(ambiente)) {
                enabledByEnv = false;
            }
            sessionStorage.setItem('logrocketEnabled', String(enabledByEnv));
            sessionStorage.setItem('allowInitLogRocket', String(true));

            if (enabledByEnv) {
                LogRocket.init('olyhcb/cl-sura-asesor', {
                    rootHostname: environment.LOGROCKET.SITE_DOMAIN
                });
                console.info('LogRocket initiated');
            }
        } catch (error) {
            console.error(error);
        }
    }

    public async logRocketSendThirdParty(): Promise<void> {
        try {
            const ambiente = environment.LOGROCKET.ENV.toString().trim().toUpperCase();
            const logRocketFilter = await this.contentfulService.getEntryFromFront(environment.KEYS.CONTENTFUL.FILTER_LOGROCKET_BY_ENV);
            const environmentList = logRocketFilter.environmentList.environments;
            if (logRocketFilter.enabled && environmentList.includes(ambiente)) {
                return;
            }
            const options = {
                mergeIframes: true,
                childDomains: ['*'],
                network: {
                    requestSanitizer(request): any {
                        if (request.headers.Authorization) {
                            request.body = null;
                            request.headers.Authorization = null;
                        }
                        return request;
                    },
                    responseSanitizer(response): any {
                        response.headers.Authorization = null;
                        response.body = null;
                        return response;
                    },
                },
                rootHostname: environment.LOGROCKET.SITE_DOMAIN
            };
            LogRocket.init('olyhcb/cl-sura-asesor', options);
        } catch (error) {
            console.error(error);
        }
    }

    alertaMensaje(button: string, titulo: string, mensaje: string){
        const optionsMessage: OptionsMessage = {
            textButton: button,
            buttonStyle: 'outline-grey',
            showButton: true,
            sizeButton: 'md',
            icon: 'assets/img/icono-escudo-info.svg',
            showIcon: true,
            titleMessage: titulo,
            message: mensaje,
        };
        const config = {
            panelClass: 'modal-800',
            disableClose: true,
            data: {
                optionsMessage,
            },
        };
        this.dialogService.openDialog(DialogMessageComponent, config);
    }

    closeMultisesion(){
        const enabledByEnv = sessionStorage.getItem('logrocketEnabled');
        const datosAd =  localStorage.getItem('datosAd');
        localStorage.clear();
        sessionStorage.clear();
        this.setUserLoggedIn(false);
        this.closeDatadogSession();
        sessionStorage.setItem('logrocketEnabled', enabledByEnv);
        localStorage.setItem('datosAd', datosAd);
        location.reload();
    }
}